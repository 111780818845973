import React from "react"
import { graphql } from "gatsby"
import { BoxContainer, Section } from "../components/primitives"
import { LivewallPageQuery } from "../../graphql-types"
import axios from "axios"
import Header from "../components/header"
import styled from "styled-components"
import SEO from "../components/seo"
import * as moment from "moment"
import "moment/locale/nl"

moment.locale("nl")

// @ts-ignore
export const transformInstagramResult = (data) => {
  return data.graphql.user.edge_owner_to_timeline_media.edges.map(
    // @ts-ignore
    ({ node }) => {
      return {
        image: node.thumbnail_src,
        is_video: node.is_video,
        caption: node.edge_media_to_caption.edges[0].node.text,
        url: `https://instagram.com/p/${node.shortcode}`,
        id: node.id,
        taken_at: node.taken_at_timestamp,
      }
    }
  )
}

export type PostProps = {
  image: string
  is_video: boolean
  caption: string
  url: string
  id: string
  taken_at: number
}

const LiveWall = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  gap: 2rem;

  & > * {
    background: ${(props) => props.theme.colors.background};
    min-height: 35vh;
    width: 100%;
    align-self: flex-start;
    text-decoration: none;
    color: inherit;

    & > * + * {
      margin-top: 1rem;
    }

    p {
      padding: 0 1rem;
    }

    div {
      display: flex;
      height: 4rem;
      width: 100%;
      align-items: center;
      border-top: 1px solid ${(props) => props.theme.colors.border};
      color: ${(props) => props.theme.colors.text};

      span:nth-child(1) {
        padding-left: 2rem;
        color: ${(props) => props.theme.colors.label};
      }

      span:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        width: 4rem;
        height: 4rem;
        border-left: 1px solid ${(props) => props.theme.colors.border};
      }
    }
  }
`

export const PageLivewall: React.FC<{ data: LivewallPageQuery }> = ({
  data,
}) => {
  const [posts, setPosts] = React.useState<PostProps[]>([])
  const [isFetching, setIsFetching] = React.useState(true)

  const username = "restaurantwollegras"

  React.useEffect(() => {
    axios
      .get(`https://restaurantwollegras.nl/instagram.json`)
      .then((res) => {
        setPosts(transformInstagramResult(res.data))
        setIsFetching(false)
      })
      .catch((err) => console.error(err))
  }, [])

  return (
    <>
      <SEO data={data.wp?.page?.seo || undefined} wp={data.wp || undefined} />
      <Header />
      <Section
        css={`
          background: ${(props) => props.theme.colors.sectionBackground};
        `}
      >
        <BoxContainer>
          <h2
            css={`
              margin-bottom: 1rem;
            `}
          >
            Updates
          </h2>
          <LiveWall>
            {isFetching && (
              <>
                <div className="skeleton" />
                <div className="skeleton" />
                <div className="skeleton" />
                <div className="skeleton" />
                <div className="skeleton" />
                <div className="skeleton" />
              </>
            )}
            {!isFetching &&
              posts.length > 0 &&
              posts.map((post) => (
                <a key={post.id} href={post.url} target="_blank" rel="noopener">
                  <img src={post.image} alt="" />
                  <p>
                    {post.caption.length >= 180 ? (
                      <>
                        {`${post.caption.substring(0, 180)}...`}{" "}
                        <span>Lees verder</span>
                      </>
                    ) : (
                      post.caption
                    )}
                  </p>
                  <div>
                    <span>{moment(post.taken_at, "X").fromNow()}</span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.218"
                        height="15.218"
                      >
                        <g fill="currentColor">
                          <path d="M10.559 15.218h-5.9A4.664 4.664 0 010 10.559v-5.9A4.665 4.665 0 014.659 0h5.9a4.665 4.665 0 014.659 4.659v5.9a4.664 4.664 0 01-4.659 4.659zm-5.9-14.079a3.524 3.524 0 00-3.52 3.52v5.9a3.524 3.524 0 003.52 3.52h5.9a3.524 3.524 0 003.52-3.52v-5.9a3.524 3.524 0 00-3.52-3.52z" />
                          <path d="M7.609 11.459a3.849 3.849 0 113.85-3.849 3.854 3.854 0 01-3.85 3.849zm0-6.532a2.683 2.683 0 102.683 2.683 2.686 2.686 0 00-2.683-2.683z" />
                          <path d="M12.879 3.341a1 1 0 11-1-1 1 1 0 011 1z" />
                        </g>
                      </svg>
                    </span>
                  </div>
                </a>
              ))}
          </LiveWall>
        </BoxContainer>
      </Section>
    </>
  )
}

export const query = graphql`
  query LivewallPage($id: ID!) {
    wp {
      page(id: $id) {
        uri
        title
        content
        featuredImage {
          ...FeaturedImage
        }
        seo {
          ...SEO
        }
      }
    }
  }
`

export default PageLivewall
